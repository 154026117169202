import React from 'react';
import './style.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-light-svg-icons";
import TopActions from "../TopActions";

const cws = '//nanoporetech.com';

export default function Home() {
    return(
        <div class="homepage">
            <div class="full-width">
            <TopActions isLight={true} main="main" style="margin-top:1rem;"/>
                <nano-slides class="products__slides products__landing-header" navbtns="true" pager="true" animation="parallax">
                    <nano-slide class="products__slides-slide products__slides-slide--all">
                        <div class="products__slide-container">

                            {/* <h3 class="heading--meta"><a href="/"><span>Home</span></a> <FontAwesomeIcon icon={faChevronRight} /> Products</h3> */}
                            <div class="grid">
                                <div class="col col-6 col-md">
                                    <h1 class="heading heading--one">Choose nanopore sequencing for:</h1>
                                    <img src="/sites/default/files/s3/products/nanopore-sequencing-devices-group.png" class="slideImg"/>
                                    <div class="products__landing-header-actions">
                                        <a href="/sites/default/files/s3/literature/product-brochure.pdf" class="button button--light button--keyline visible">Download product brochure <FontAwesomeIcon icon={faChevronRight} /></a>
                                    </div>
                                </div>
                                <div class="col col-6 col-md">
                                    <ul class="products__slides-bullets">
                                        <li>Sequencing any DNA/RNA fragment length from short to ultra-long</li>
                                        <li>Direct sequencing of native DNA/RNA</li>
                                        <li>Availability of data in real time</li>
                                        <li>Simple &amp; rapid, or automated, library prep</li>
                                        <li>No capital investment</li>
                                        <li>Portable devices to ultra-high throughput desktop devices</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nano-slide>
                    <nano-slide class="products__slides-slide products__slides-slide--mk1c">
                        <div class="products__slide-container">
                            <div class="grid">
                                <div class="col col-6 col-md">
                                    <h1 class="heading heading--one">MinION Mk1C. Fully integrated, portable sequencing and analysis.</h1>
                                    <p>Combining the real-time, rapid, portable sequencing of MinION with powerful computing and a high-resolution screen.</p>
                                    <p>The complete, go-anywhere solution for long-read DNA and RNA sequencing and analysis.</p>
                                </div>
                                <div class="col col-6 col-md">
                                </div>
                            </div>
                        </div>
                    </nano-slide>
                </nano-slides>
            </div>
            <div class="full-width products__apps">
                <div class="full-width__container">
                    <div class="products__apps-text">
                        <p>Looking for flow cells, sample kits and consumables?</p>
                    </div>
                    <div class="products__apps-key">
                        <img src="/sites/default/files/s3/products/consumables-kits.png" alt="Sample kits" />
                        <a class="button button--light button--keyline visible" href="/sample-prep.html">All sample kits <FontAwesomeIcon icon={faChevronRight} /></a>
                        <img src="/sites/default/files/s3/products/consumables-flow-cells.png" alt="Flow cells" />
                        <a class="button button--light button--keyline visible" href="/flow-cells.html">All flow cells <FontAwesomeIcon icon={faChevronRight} /></a>
                    </div>
                </div>
            </div>
            <div class="full-width products__sequence">
                <div class="full-width__container">
                    <h2 class="products__heading">Sequence and analyse</h2>
                    <form name="comparison" action={cws + '/products/comparison'}>
                    {/* <div class="products__heading-actions">
                     <input type="submit" class="button button--light button--keyline button--icon" data-icon="" value="Compare selected products"/>
                    </div> */}
                        <div class="grid">
                            <div class="col col-md">
                                <div class="products__item">
                                    <a href="/minion-mk1c.html" class="products__item-device">
                                        <img src="/sites/default/files/s3/products/sequence-minion.png" alt="MinION device" />
                                    </a>
                                    <a href="/minion-mk1c.html" class="products__item-logo">
                                        <img src="/sites/default/files/s3/products/minion-logo-white.png" alt="MinION" />
                                    </a>
                                    <div class="products__item-price">
                                        <div class="products__item-keys">
                                            <a href="/minion-mk1c.html"><img src="/sites/default/files/s3/products/r-white.svg" alt="Research use" /></a>
                                            <a href="/products/qline" class="qline"><img src="/sites/default/files/s3/products/q-white.svg" alt="Q-Line" /></a>
                                            <a href="https://oxfordnanoporedx.com" class="ivd"><img src="/sites/default/files/s3/products/ivd-white.svg" alt="IVD Testing" /></a>
                                        </div>
                                        <h3 class="heading--meta">&nbsp;</h3>
                                    </div>
                                    <a href="/gridion.html">
                                        <span>The only portable, real-time devices for DNA and RNA sequencing, putting you in control of your sequence data. Access to sequencing for $1,000. Sequence immediately, not wait. Sequence outside a lab. 10–20Gb per 48 hrs. Avoid CapEx investments.</span>
                                        <h4>Why choose MinION?</h4>
                                        <ul>
                                            <li>Pocket-sized, portable device for biological analysis</li>
                                            <li>Up to 512 nanopore channels</li>
                                            <li>Simple 10-min sample prep available</li>
                                            <li>Real-time analysis for rapid, efficient workflows</li>
                                            <li>Adaptable to direct DNA or RNA sequencing</li>
                                            <li>MinIT available to support IT/software needs</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                            <div class="col col-md">
                                <div class="products__item">
                                    <a href="/gridion.html" class="products__item-device">
                                        <img src="/sites/default/files/s3/products/sequence-gridion.png" alt="GridION device" />
                                    </a>
                                    <a href="/gridion.html" class="products__item-logo">
                                        <img src="/sites/default/files/s3/products/gridion-logo-white.png" alt="GridION" />
                                    </a>
                                    <div class="products__item-price">
                                        <div class="products__item-keys">
                                            <a href="/gridion.html"><img src="/sites/default/files/s3/products/r-white.svg" alt="Research use" /></a>
                                            <a href="/products/qline" class="qline"><img src="/sites/default/files/s3/products/q-white.svg" alt="Q-Line" /></a>
                                            <img src="/sites/default/files/s3/products/ivd-blue.svg" alt="IVD Testing" />
                                        </div>
                                        <h3 class="heading--meta">&nbsp;</h3>
                                    </div>
                                    <a href="/gridion.html">
                                        <span>Compact benchtop device designed to run and analyse up to five Flongle or MinION Flow Cells. Scale up from MinION. Offer nanopore sequencing as a service. Choice to invest from a capital or consumable budget.</span>
                                        <h4>Why choose GridION?</h4>
                                        <ul>
                                            <li>Run up to five independently addressable Flongle or MinION Flow Cells</li>
                                            <li>As much as 150 Gb of data — streamed in real time for immediate analysis</li>
                                            <li>Powerful onboard data processing and analysis, minimising IT requirements</li>
                                            <li>Small footprint, suitable for any lab</li>
                                            <li>Pay just for consumables</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                            <div class="col col-md">
                                <div class="products__item">
                                    <a href="/promethion.html" class="products__item-device">
                                        <img src="/sites/default/files/s3/products/sequence-promethion.png" alt="PromethION device" />
                                    </a>
                                    <a href="/promethion.html" class="products__item-logo">
                                    <   img src="/sites/default/files/s3/products/promethion-logo-white.png" alt="PromethION" />
                                    </a>
                                    <div class="products__item-price">
                                        <div class="products__item-keys">
                                            <a href="/promethion.html"><img src="/sites/default/files/s3/products/r-white.svg" alt="Research use" /></a>
                                            <img src="/sites/default/files/s3/products/q-blue.svg" alt="Q-Line" />
                                            <img src="/sites/default/files/s3/products/ivd-blue.svg" alt="IVD Testing" />
                                        </div>
                                        <h3 class="heading--meta">&nbsp;</h3>
                                    </div>
                                    <a href="/promethion.html">
                                        <span>Large-scale, long-read, direct DNA and RNA sequencing. Offer nanopore sequencing as a service. Very large data volumes projects (Tb).
                                            On-demand sequencing for large numbers of samples. Avoid CapEx investments.</span>
                                        <h4>Why choose PromethION?</h4>
                                        <ul>
                                            <li>Run up to 48 independently addressable, high-capacity PromethION Flow Cells</li>
                                            <li>Generate terabases of data — streamed in real time for immediate analysis</li>
                                            <li>Alleviate data analysis bottlenecks</li>
                                            <li>Generate sub-$1,000 human genomes</li>
                                            <li>Pay just for consumables</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="products__row">
                            <div class="products__row-price">
                                <a href="/flongle-starter-pack.html" class="products__row-logo">
                                    <img src="/sites/default/files/s3/products/flongle-logo-white.png" alt="Flongle" />
                                </a>
                                <div class="products__row-keys">
                                    <a href="/flongle-starter-pack.html"><img src="/sites/default/files/s3/products/r-white.svg" alt="Research use" /></a>
                                    <img src="/sites/default/files/s3/products/q-blue.svg" alt="Q-Line" />
                                    <a href="https://oxfordnanoporedx.com" class="ivd"><img src="/sites/default/files/s3/products/ivd-white.svg" alt="IVD Testing" /></a>
                                </div>
                            </div>
                            <a href="flongle-starter-pack.html" class="products__row-wrapper">
                                <div class="products__row-device">
                                    <img src="/sites/default/files/s3/products/sequence-flongle.png" alt="Flongle device" />
                                </div>
                                <div class="products__row-content">
                                    <h3 class="heading--meta">&nbsp;</h3>
                                    <span>Flongle is an adapter for MinION or GridION that enables direct, real-time DNA sequencing, or cDNA sequencing on smaller, single-use flow cells.</span>
                                    <h4>Why choose Flongle?</h4>
                                    <ul>
                                    <li>Costing just $90 per flow cell, Flongle is the quickest, most accessible solution for smaller tests and experiments.</li>
                                    <li>Immediate access to data for rapid, actionable results.</li>
                                    <li>Rapidly assess sample quality prior to starting a larger sequencing experiment.</li>
                                    <li>Run single samples on demand instead of multiplexing.</li>
                                    </ul>
                                </div>
                            </a>
                        </div>
                    </form>
                    <div class="products__highlight">
                        <div class="products__highlight-content">
                            <h4>Nanopore analysis</h4>
                            <p>From the moment data acquisition begins, analysis can be performed in real time. Find out more about analysis solutions for nanopore sequencing data.</p>
                        </div>
                        <a href={cws + "/learn-more"} class="button button--light button--keyline visible">Learn more <FontAwesomeIcon icon={faChevronRight} /></a>
                    </div>
                </div>
            </div>
            <div class="full-width products__prepare">
                <div class="full-width__container">
                    <h2 class="products__heading">Prepare</h2>
                    <div class="products__heading-actions">
                        <a href="/sequencing-kits.html" class="button button--light button--keyline visible">Compare sequencing kits <FontAwesomeIcon icon={faChevronRight} /></a>
                    </div>
                    <div class="products__row">
                        <div class="products__row-price">
                            <a href="/voltrax-capex.html" class="products__row-logo">
                                <img src="/sites/default/files/s3/products/voltrax-logo-white.png" alt="VolTRAX" />
                            </a>
                            <div class="products__row-keys">
                                <a href="/products/voltrax"><img src="/sites/default/files/s3/products/r-white.svg" alt="Research use" /></a>
                                <img src="/sites/default/files/s3/products/q-blue.svg" alt="Q-Line" />
                                <img src="/sites/default/files/s3/products/ivd-blue.svg" alt="IVD Testing" />
                            </div>
                        </div>
                        <a href="/voltrax-capex.html" class="products__row-wrapper">
                            <div class="products__row-device">
                                <img src="/sites/default/files/s3/products/sequence-voltrax.png" alt="VolTRAX" />
                            </div>
                            <div class="products__row-content">
                                <h3 class="heading--meta">&nbsp;</h3>
                                <span>Automatic library preparation: VolTRAX is designed as an alternative to a range of lab equipment, enabling the preparation of your biological samples, ready for analysis, hands-free.</span>
                                <h4>Why choose VolTRAX?</h4>
                                <ul>
                                    <li>Only minutes of hands-on time, even for novel/complex experiments</li>
                                    <li>Take sample prep out of the lab</li>
                                    <li>Develop own sample and library prep methods, including fluorescence detection and incubations</li>
                                    <li>Select your protocol, load, and leave</li>
                                </ul>
                            </div>
                        </a>
                    </div>

                    <div class="grid products__kits">
                        <div class="col col-3 col-md">
                            <a href="/sequencing-kits.html" class="products__item">
                                <img src="/sites/default/files/s3/products/wgs-kits.png" alt="A Whole genome sequencing kit" />
                                <h4>Whole genome sequencing kits</h4>
                                <span>Conduct long-read sequencing of native DNA, eliminating the potential for PCR bias and allowing the detection of base modifications alongside nucleotide sequence. <u>More ></u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="/sequencing-kits.html?filter=dna" class="products__item">
                                <img src="/sites/default/files/s3/products/targeted-kits.png" alt="A Targeted DNA sequencing kit" />
                                <h4>Targeted DNA sequencing kits</h4>
                                <span>Cost-effective, assembly-free analysis of repetitive regions, structural variants, and variant phasing across entire genes or large genomic regions of interest. <u>More ></u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="/sequencing-kits.html?filter=rna" class="products__item">
                                <img src="/sites/default/files/s3/products/rna-kits.png" alt="An RNA sequencing kit" />
                                <h4>RNA sequencing kits</h4>
                                <span>Confidently characterise and quantify full-length RNA transcripts, splice variants, and fusions using long-read nanopore sequencing. <u>More ></u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="/sequencing-kits.html" class="products__item">
                            <img src="/sites/default/files/s3/products/barcoding-kits.png" alt="A barcoding kit" />
                            <h4>Barcoding kits</h4>
                            <span>Run multiple DNA or RNA samples on a single flow cell. Buy either as dedicated kits, or to enhance your existing sequencing kit. <u>More ></u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="/expansion-packs.html" class="products__item">
                            <img src="/sites/default/files/s3/products/expansion-packs.png" alt="An expansion pack" />
                            <h4>Expansion packs</h4>
                            <span>A range of add-on packs to enhance your existing native or amplification-based nanopore sequencing kits. <u>More&gt;</u></span>
                            </a>
                        </div>
                    </div>

                    <div class="products__highlight">
                        <div class="products__highlight-content">
                            <h4>Protocol builder</h4>
                            <p>Optimise your complete nanopore sequencing workflow – from extraction to analysis.</p>
                        </div>
                        <a href="https://community.nanoporetech.com/knowledge/protocol_builder" class="button button--light button--keyline visible">Learn more <FontAwesomeIcon icon={faChevronRight} /></a>
                    </div>
                </div>
            </div>
            <div class="full-width products__flow">
                <div class="full-width__container">
                    <h2 class="products__heading">Flow cells</h2>
                    <div class="grid products__kits">
                        <div class="col col-3 col-md">
                            <a href="/flongle-flow-cell-r9-4-1.html" class="products__item">
                            <img src="/sites/default/files/s3/products/flongle-minion.png" height="108" alt="Flongle with flow cell and MinION" />
                            <h4>Flongle flow cells</h4>
                            <span>Flow cells designed for smaller, frequent, rapid tests and analyses. <u>More &gt;</u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="promethion-flow-cell-packs-111.html" class="products__item">
                            <img src="/sites/default/files/s3/products/promethion-flow-cells.png" height="108" alt="PromethION Flow cells" />
                            <h4>PromethION flow cells</h4>
                            <span>Up to 3000 nanopore channels for DNA or RNA sequencing in real time. <u>More &gt;</u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="/flow-cell-r9-4-1.html" class="products__item">
                            <img src="/sites/default/files/s3/products/minion-gridion-flow-cells.png" height="108" alt="MinION and GridION Flow cells" />
                            <h4>MinION &amp; Gridion flow cells</h4>
                            <span>Up to 512 nanopore channels for DNA or RNA sequencing in real time. <u>More &gt;</u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="voltrax-cartridge-pack-configurable.html" class="products__item">
                            <img src="/sites/default/files/s3/products/voltrax-flow-cell.png" height="108" alt="VolTRAX Flow cell" />
                            <h4>VolTRAX Cartridges</h4>
                            <span>Liquids move around the cartridge in a software programmed path, performing reactions in sequence. <u>More &gt;</u></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="full-width products__services">
                <div class="full-width__container">
                    <h2 class="products__heading">Services</h2>
                    <div class="grid">
                    <div class="col col-3 col-md">
                            <a href="/advanced-training..html" class="products__services">
                            <img src="/sites/default/files/s3/products/nanopore-training.jpg" height="108" alt="Flongle with flow cell and MinION" />
                            <h4>Training</h4>
                            <span>We offer training on all our sequencing devices, including workshops and bespoke training packages inclusive of kits and flow cells:</span>

                            <span class="links">Rapid start day training</span>
                            <span class="links">Advanced training</span>
                            <span class="links">Nanopore workshop</span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="device-warranty.html" class="products__services">
                            <img src="/sites/default/files/s3/products/nanopore-device-warranty.jpg" height="108" alt="PromethION Flow cells" />
                            <h4>Device warranty</h4>
                            <span>Designed to ensure your instrument is performing optimally by providing the most up-to-date hardware and software. <u>More &gt;</u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="/certification.html" class="products__services">
                            <img src="/sites/default/files/s3/products/nanopore-certification.jpg" height="108" alt="MinION and GridION Flow cells" />
                            <h4>Device&amp; Lab Certification</h4>
                            <span>the GridION and PromethION certification programmes are designed to ensure certified service labs can offer the highest quality service on Oxford Nanopore sequencing platforms. <u>More &gt;</u></span>
                            </a>
                        </div>
                        <div class="col col-3  col-md">
                            <a href="nanopore-workshop.html" class="products__services">
                            <img src="/sites/default/files/s3/products/nanopore-services.jpg" height="108" alt="VolTRAX Flow cell" />
                            <h4>Bespoke Projects Services</h4>
                            <span>Discuss your bespoke training, project support or consultancy needs with us so we can offer the right solution for you. <u>More &gt;</u></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="full-width products__devel">
                <div class="full-width__container">
                    <h2 class="products__heading">In development</h2>
                    <div class="products__heading-actions">
                        <a href={cws + "/how-it-works"} class="button button--light button--keyline visible">Learn about nanopore technology <FontAwesomeIcon icon={faChevronRight} /></a>
                    </div>
                    <div class="grid">
                        <div class="col col-md">
                            <div class="products__row-wrapper">
                                <div class="products__row-device">
                                    <img src="/sites/default/files/s3/products/SmidgION-2018.png" alt="SmidgION" />
                                </div>
                                <div class="products__row-content">
                                    <div class="products__row-logo">
                                        <img src="/sites/default/files/s3/products/smidgion-logo-white.svg" alt="SmidgION" />
                                    </div>
                                    <ul>
                                    <li>Designed to be our smallest sequencing device so far</li>
                                    <li>Same nanopore sensing technology as MinION and PromethION</li>
                                    <li>Designed for use with a smartphone in any location</li>
                                    </ul>
                                    <a href={cws + "/products/smidgion"} class="button button--primary">Learn more <FontAwesomeIcon icon={faChevronRight} /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col col-md">
                            <div class="products__row-wrapper">
                                <div class="products__row-device">
                                    <img src="/sites/default/files/s3/products/plongle-black.png" alt="Plongle" />
                                </div>
                                <div class="products__row-content">
                                    <div class="products__row-logo">
                                        <img src="/sites/default/files/s3/products/plongle-logo-white.svg" width="84" height="24" alt="Plongle" />
                                    </div>
                                    <ul>
                                        <li>High-throughput, real-time, long-read sequencing for smaller tests or experiments</li>
                                        <li>Cost-efficient, 96-well plate format</li>
                                        <li>Compatible with high-throughput automation</li>
                                    </ul>
                                    <a href={cws + "/products/plongle"} class="button button--primary">Learn more <FontAwesomeIcon icon={faChevronRight} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="investigation__related">
                <div class="site-main">
                    <div class="grid">
                        <div class="col col-md">
                            <h3 class="heading--meta">Recommended for you</h3>
                            <div class="recommended-content recommended-content--apps">
                                <a class="related" href="#">
                                    <span class="related-image"><img alt="" data-entity-type="" data-entity-uuid="" src="https://placeimg.com/642/482/nature" /></span>
                                    <span class="related-content">
                                        <span class="related-title">Cas9-Assisted Targeting of Chromosome segments (CATCH) for targeted nanopore sequencing and optical genome mapping</span>
                                        <span class="publication__type">Publication</span>
                                        <span class="related-date">1st Jan 2020</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="col">
                            <h3>Further reading</h3>
                            <a href={cws + "/about/for-investors"} class="button button--primary"><span>Investors</span><span><FontAwesomeIcon icon={faChevronRight} /></span></a>
                            <a href={cws + "/resource-centre"} class="button button--primary"><span>Researchers</span><span><FontAwesomeIcon icon={faChevronRight} /></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
