import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { gql, useLazyQuery } from "@apollo/client";
import { isTransactional } from '../hooks/siteData';
import Home from '../components/Home';
import Catalog from '../components/Catalog';

export default function Index(props) {
    const [loadCategories, { called, loading, data }] = useLazyQuery(CATEGORIES, {fetchPolicy: 'network-only'});

    if (isTransactional()) {
        if (!called) loadCategories();
        if (data) return <Catalog category={0} categories={data.categoryList[0].children} />
        return <center><br/><FontAwesomeIcon icon={faSpinner} size="lg" spin /></center>
    }

    return <Home />;
}

const CATEGORIES = gql`
    fragment category on CategoryInterface {
        id
        name
        url_key
        is_anchor
        position
        level
    }

    query {
        categoryList(filters: { name: { match: "Default Category" } }) {
            children {
                ... category
                products {
                    items {
                        id
                        name
                        url_key
                    }
                }
                children {
                    ... category
                    children {
                        ... category
                    }
                }
            }
        }
    }
`;
